import "./App.css";
import ParaLayer from "./sections/ParaLayer";
function App() {
  return (
    <div className="App">
      <ParaLayer />
    </div>
  );
}

export default App;
